import React from 'react';


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
            
            </ul>
            
        </nav>
    )
}

export default Nav;