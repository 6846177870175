import React from 'react';
import Logo from '../../elements/logo/Logo';
import Nav from '../../common/header/Nav';

const HeaderOne = () => {

    return (
        <>
            <header className="header axil-header header-style-1">
                <div className={`axil-mainmenu`}>
                    <div className="container">
                        <div className="header-navbar">
                            <div className="header-logo">
                            <Logo limage="/images/digitalroamers-transparent.png"
                                dimage="/images/digitalroamers-transparent.png"
                                simage="/images/digitalroamers-transparent.png"
                                />      
                            </div>
                            <div className="header-main-nav">
                                <Nav />
                            </div>
                           
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default HeaderOne;