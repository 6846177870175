import React from 'react';

import styles from './ServiceProp.module.css'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PublicIcon from '@mui/icons-material/Public';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import NightlightIcon from '@mui/icons-material/Nightlight';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TagIcon from '@mui/icons-material/Tag';
import SpeedIcon from '@mui/icons-material/Speed';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ReviewsIcon from '@mui/icons-material/Reviews';
import NetworkWifi1BarIcon from '@mui/icons-material/NetworkWifi1Bar';

const ServicePropOne = ({colSize, serviceStyle, itemShow, marginTop}) => {

	return (
		<>

		<div className={`${colSize}`}>
				<div className={`services-grid ${serviceStyle}`}>
					<div className="thumbnail">
						<CurrencyPoundIcon className={styles.cloud} />
					</div>
					<div className="content">
						<h5 className="title">
							Pay local prices
						</h5>
						<p>No contract, no hidden fees, no unexpected roaming charges</p>
					</div>
				</div>
			</div>

			<div className={`${colSize}`}>
				<div className={`services-grid ${serviceStyle}`}>
					<div className="thumbnail">
						<SpeedIcon className={styles.cloud} />
					</div>
					<div className="content">
						<h5 className="title">
						Connect Instantly
						</h5>
						<p>No more waiting in line, get your eSIM contactlessly within 5 mins</p>
					</div>
				</div>
			</div>
			
			<div className={`${colSize}`}>
				<div className={`services-grid ${serviceStyle}`}>
					<div className="thumbnail">
						<SwapHorizIcon className={styles.cloud} />
					</div>
					<div className="content">
						<h5 className="title">
						Convenient & Flexible
						</h5>
						<p>Keep your primary SIM for call & text, no more swapping SIMs at the airport</p>
					</div>
				</div>
			</div>


			<div className={`${colSize}`}>
				<div className={`services-grid ${serviceStyle}`}>
					<div className="thumbnail">
						<PublicIcon className={styles.cloud} />
					</div>
					<div className="content">
						<h5 className="title">
							50+ countries supported
						</h5>
						<p>Works internationally</p>
					</div>
				</div>
			</div>


			<div className={`${colSize}`}>
				<div className={`services-grid ${serviceStyle}`}>
					<div className="thumbnail">
						<ReviewsIcon className={styles.cloud} />
					</div>
					<div className="content">
						<h5 className="title">
							2000+ Reviews on Trustpilot
						</h5>
						<p>Trusted by thousands with an average of 4.4 out of 5 stars</p>
					</div>
				</div>
			</div>


			<div className={`${colSize}`}>
				<div className={`services-grid ${serviceStyle}`}>
					<div className="thumbnail">
						<NetworkWifi1BarIcon className={styles.cloud} />
					</div>
					<div className="content">
						<h5 className="title">
						Don't rely on bad wifi
						</h5>
						<p>No need to rely on poor wifi to run your business</p>
					</div>
				</div>
			</div>

			{/* <div className={`${colSize}`}>
			<div className={`services-grid ${serviceStyle}`}>
				<div className="thumbnail">
					<CloudIcon className={styles.cloud} />
				</div>
				<div className="content">
					<h5 className="title">
						Hosted in the cloud
					</h5>
					<p>You can be reset assured that the service highly available with 99.99% uptime, super fast and secure.</p>
				</div>
			</div>
		</div> */}

		
			

			{/* <div className={`${colSize}`}>
				<div className={`services-grid ${serviceStyle}`}>
					<div className="thumbnail">
						<HearingIcon className={styles.cloud} />
					</div>
					<div className="content">
						<h5 className="title">
							UK Ringtone
						</h5>
						<p>no matter where you are in the world, your clients will still hear a UK dialing tone. So they'll never know you're enjoying an Acai bowl in bali!</p>
					</div>
				</div>
			</div> */}


			

			</>
		

	)

   
}

export default ServicePropOne;